<template>
  <a-modal title="人员详情" width="600px" v-model="visible" :footer="null" @cancel="hide">
    <div class="modal-details-item">
      <div class="avatar">
        <a-avatar shape="square" :size="180" :src="item.img || defaultUser" />
      </div>
      <div class="user-detail">
        <div class="user-name">{{ formatName(item.realName) }}</div>
        <div class="user-skill" v-if="type === '2'">专业：{{ item.position }}</div>
        <div class="user-content">
          <div class="user-position">{{ type === '1' ? item.position : qualObj[item.qualificationCertificate] }}</div>
          <div class="user-line"></div>
          <div class="user-eval">{{ levelObj[item.skillLevelCertificate] }}</div>
        </div>
        <div class="user-skill" v-if="type === '1'">基本技能：{{ item.baseSkill }}</div>
        <div class="user-experienced">项目经历：{{ workObj[item.experience] }}</div>
        <div class="card-bottom">
          <div class="user-label-title">专业技能：</div>
          <div class="user-label">
            <div
              class="user-label-item"
              v-for="(data, index) in formatSkill(type === '1' ? item.professionalSkills : item.baseSkill)"
              :key="index"
            >
              {{ data }}
            </div>
          </div>
        </div>
      </div>
    </div></a-modal
  >
</template>

<script>
import defaultUser from '@/assets/default-user.png'

const workObj = {
  1: '30万机组以下',
  2: '30-60万机组',
  3: '100万及以上机组'
}

const levelObj = {
  0: '无',
  1: '初级',
  2: '中级',
  3: '高级',
  '-1': '特殊工种证书'
}

const qualObj = {
  0: '无',
  1: '安全员',
  2: '建造师'
}
export default {
  name: 'ItemUser',
  props: {
    // type: {
    //   type: String,
    //   default: '1'
    // },
    // item: {
    //   type: Object,
    //   default: () => ({})
    // }
  },
  data () {
    return {
      workObj,
      levelObj,
      qualObj,
      defaultUser,
      visible: false,
      item: {},
      type: '1'
    }
  },
  methods: {
    show (data, type = '1') {
      this.item = { ...data }
      this.type = type
      this.visible = true
    },
    hide () {
      this.item = {}
      this.type = '1'
      this.visible = false
    },
    formatName (name) {
      if (!name) return
      if (name.length === 2) {
        return name.substr(0, 1) + '*'
      } else if (name.length > 2) {
        var char = ''
        for (var i = 0, len = name.length - 2; i < len; i++) {
          char += '*'
        }
        return name.substr(0, 1) + char + name.substr(-1, 1)
      }
    },
    formatSkill (skills) {
      return skills ? skills.split('、') : []
    }
  }
}
</script>

<style lang="less">
.modal-details-item {
  display: flex;
  margin: 0px 6px 15px;
  padding: 10px;
  border-radius: 10px;
  //   &:nth-child(3n) {
  //     margin-right: 0px;
  //   }
  .avatar {
    margin-right: 20px;
  }
  .user-detail {
    flex: 1;
    height: 120px;
    .user-name {
      font-weight: 400;
      font-size: 20px;
      color: #333333;
    }
    .user-content {
      display: flex;
      align-items: center;
      .user-line {
        height: 12px;
        width: 2px;
        margin: 0px 6px 0px 6px;
        background: #666666;
      }
    }
    .user-skill,
    .user-experienced,
    .user-content {
      margin-top: 8px;
    }
    .user-eval {
      color: rgba(229, 24, 24, 1);
    }
  }
  .card-bottom {
    padding-top: 12px;
    display: flex;
    align-items: center;
    .user-label-title {
      min-width: 70px;
    }
    .user-label {
      &-item {
        background: rgba(22, 119, 255, 0.1);
        border-radius: 2px;
        padding: 2px 6px;
        margin-right: 6px;
        display: inline-block;
        color: #1677ff;
      }
    }
  }
}
</style>
